export default class TarifClass {
    static dataTarif(data) {
        if (data) {
            if (Array.isArray(data)) {
                return data.map(item => {
                    return {
                        id: item.ID,
                        nama: item.nama,
                        deskripsi: item.description,
                        jumlah: item.jumlah
                    };
                });
            }
        }
    }
    static postDataTarif(data) {
        //     {
        // "nama": "Tarif Post 1 HOST",
        // "description": "Tarif Post 1 HOST",
        // "jumlah": 5000
        // }
        if (data) {
            if (typeof data == "object") {
                return {
                    nama: data.nama,
                    description: data.deskripsi,
                    jumlah: Number(data.jumlah)
                };
            }
        }
    }
    static putDataTarif(data) {
        if (data) {
            if (typeof data == "object") {
                return {
                    ID: data.id,
                    nama: data.nama,
                    description: data.deskripsi,
                    jumlah: Number(data.jumlah)
                };
            }
        }
    }
    static confirmationMapper(data) {
        if (data) {
            return data.map(item => {
                return `${item.ID} - ${item.nama}`;
            });
        }
    }
}
