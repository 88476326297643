<template>
    <Modal class="custom-modal doctor-filter">
        <ModalHeader class="custom-header"
            ><h3>{{ modalTitle }}</h3>
            <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                @click.prevent="dismiss(idComponent)"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </ModalHeader>
        <ModalBody>
            <form class="form-modal">
                <div class="form-group">
                    <label class=" col-form-label form-label">ID</label>
                    <div>
                        <input
                            v-model="filterData.id"
                            type="number"
                            placeholder="Tulis ID Tarif"
                        />
                    </div>
                </div>
                <div class="form-group">
                    <label class=" col-form-label form-label">Tarif</label>
                    <div>
                        <input
                            v-model="filterData.nama"
                            type="text"
                            placeholder="Tulis Nama Tarif"
                        />
                    </div>
                </div>
                <div class="form-group">
                    <label class="col-form-label form-label">Jumlah</label>
                    <div>
                        <span>Rp.</span>
                        <input
                            v-model="filterData.jumlah"
                            type="number"
                            placeholder="Tulis Jumlah Tarif"
                            style="width: 50%;"
                        />
                    </div>
                </div>
            </form>
        </ModalBody>
        <div
            class="form-divider"
            style="margin-left: 24px;margin-right: 24px;"
        ></div>
        <ModalFooter class="custom-footer">
            <div class="d-flex">
                <a
                    class="link-batal"
                    style="margin-right: 40px;line-height: 40px;"
                    @click.prevent="dismiss(idComponent)"
                    >Batal</a
                >
                <button
                    @click.prevent="saveFilter"
                    class="btn btn-primary custom-modal-button"
                >
                    Terapkan
                </button>
            </div>
        </ModalFooter>
    </Modal>
</template>

<script>
import Modal from "../../../_modal/Modal.vue";
import ModalHeader from "../../../_modal/ModalHeader.vue";
import ModalBody from "../../../_modal/ModalBody.vue";
import ModalFooter from "../../../_modal/ModalFooter.vue";
import ModalMixin from "../../../../mixins/ModalMixins";

// import EyeIcon from "../../../_icons/EyeIcon.vue";
// import EyeIconSlashed from "../../../_icons/EyeIconSlashed.vue";

// import Multiselect from "vue-multiselect";
export default {
    name: "TarifModalFilter",
    components: {
        Modal,
        ModalHeader,
        ModalBody,
        ModalFooter,
        // EyeIcon,
        // EyeIconSlashed,

        // Multiselect
    },
    mixins: [ModalMixin],
    data() {
        return {
            modalTitle: "Filter Tarif",
            filterData: {
                id: null,
                nama: null,
                jumlah: null
            }
        };
    },
    methods: {
        saveFilter() {
            if (this.componentData) {
                if (typeof this.componentData.action == "function") {
                    this.componentData.action(this.filterData);
                }
            }
        }
    },
    created() {
        if (this.componentData) {
            if (this.componentData.initialData) {
                // this.componentData.action(this.filterData);
                if (this.componentData.initialData.nama) {
                    this.filterData.nama = this.componentData.initialData.nama;
                }
                if (this.componentData.initialData.id) {
                    this.filterData.id = this.componentData.initialData.id;
                }
                if (this.componentData.initialData.jumlah) {
                    this.filterData.jumlah = this.componentData.initialData.jumlah;
                }
            }
        }
    }
};
</script>

<style></style>
