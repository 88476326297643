<template>
    <div>
        <base-header
            class="sticky-base-header"
            base-title="Master Tarif"
        ></base-header>
        <TarifScreen
            :methodObject="methodObject"
            :buttonMethod="buttonMethod"
            :filterList="filterList"
            :permissionObject="permissionObject"
        >
        </TarifScreen>
    </div>
</template>

<script>
import { Event } from "vue-tables-2";
import BaseHeader from "../../_base/BaseHeader.vue";

import ModalService from "../../../services/modal.service";
import { RepositoryFactory } from "../../../repositories/RepositoryFactory";
const TarifRepo = RepositoryFactory.get("masterTarif");

import createRequestOption from "../../../repositories/RequestParamUtil";
import BlockUI from "../../_js/BlockUI";
import TarifFilterModal from "../component/tarifModal/TarifFilterModal.vue";
import AddTarifModal from "../component//tarifModal/AddTarifModal.vue";

import DataClass from "../class/tarif-data-class";
import PermissionClass from "../class/permission-class";

import TarifScreen from "./TarifScreen.vue";

import SwalCustom from "../../_general/SwalCustom.vue";
const SweetAlert = Vue.extend(SwalCustom);

export default {
    name: "MasterTarifController",
    components: {
        TarifScreen,
        BaseHeader
    },
    data() {
        var vx = this;
        return {
            blockUi: new BlockUI(),
            filterList: {},
            methodObject: {
                controllerReqFunctionMethod: vx.controllerReqFunction,
                controllerRespFunctionMethod: vx.controllerRespFunction,
                openModalFilterMethod: vx.openModalFilter,
                openAddTarifModalMethod: vx.openAddTarifModal,
                getDataTable: vx.emitFilterQuery,
                filterItemsDataMethod: vx.filterItemsData,
                deleteIndvFIlterMethod: vx.deleteIndvFIlter,
                deleteAllFilterMethod: vx.deleteAllFilter
            },
            buttonMethod: {
                openEditTarifModalMethod: vx.openEditTarifModal,
                deleteConfirmationTarifMethod: vx.deleteConfirmationTarif
            },
            permissionObject: {}
        };
    },
    watch: {
        filterList: {
            handler(val, oldVal) {
                this.$router.push(
                    {
                        name: "master-tarif",
                        query: this.filterList
                    },
                    e => {
                        this.routeQueryToFilterList();
                        // this.emitFilterQuery();
                    }
                );
            },
            deep: true
        }
    },
    methods: {
        initPermissionCheck() {
            let arrayPermission = localStorage.getItem("permission").split(",");
            this.permissionObject = PermissionClass.isRenderByPermission(
                arrayPermission
            );
        },
        emitFilterQuery() {
            Event.$emit(
                "vue-tables.tarif_table.filter::filterQuery",
                this.filterList
            );
        },
        showErrorPopUp(err) {
            let message = "Unknown Error Has Occurred";
            let title = "Error";

            if (err.message) {
                message = err.message;
            }
            if (err.error) {
                title = err.error;
            }
            new SweetAlert().show(
                {
                    title: title,
                    text: message,
                    allowOutsideClick: true,
                    reverseButtons: true,
                    showCancelButton: false,
                    textConfirm: "OK"
                },
                "dangerImg"
            );
        },
        controllerReqFunction(query) {
            let data = query;
            console.log(data, "controllerReqFunction");
            let params = {};
            params.page = data.page;
            params.page_size = data.limit;
            if (!_.isEmpty(this.filterList)) {
                data.filterQuery = this.filterList;
            }
            if (!data.filterQuery && !data.orderBy) {
                return TarifRepo.getTarifs({ params }).catch(async err => {
                    console.log(err);
                });
            } else {
                // if (data.filterQuery.dateRange) {
                //     params.holidayStart = data.filterQuery.dateRange.start;
                //     params.holidayEnd = data.filterQuery.dateRange.end;
                // }
                let queryNames = {
                    nama: "nama",
                    jumlah: "jumlah",
                    deskripsi: "description",
                    id: "id"
                };
                let sortNames = {
                    jumlah: "sort"
                };
                let requestParam = createRequestOption(params);
                console.log(requestParam, "requestParam", params);
                if (data.orderBy) {
                    let order = data.ascending ? "asc" : "desc";
                    requestParam.params.append(sortNames[data.orderBy], order);
                }
                Object.keys(data.filterQuery).forEach(query => {
                    if (query != "isPublish") {
                        if (data.filterQuery[query]) {
                            requestParam.params.append(
                                queryNames[query],
                                data.filterQuery[query]
                            );
                        }
                    } else {
                        if (data.filterQuery[query] == "unpublish") {
                            requestParam.params.append("isPublish", false);
                        } else {
                            requestParam.params.append("isPublish", true);
                        }
                    }
                });
                console.log(
                    requestParam.params.toString(),
                    "requestParam",
                    params
                );
                return TarifRepo.getTarifs(requestParam).catch(async err => {
                    console.log(err, "controllerReqFunction error");
                    console.log(err.message, "controllerReqFunction error 119");
                    this.showErrorPopUp(err);
                });
            }
        },
        controllerRespFunction(resp) {
            let classData = DataClass.dataTarif(resp.data);
            // console.log(resp.config.params, "133 response");
            return {
                data: classData,
                count: resp.headers["x-count"]
                // resp.headers["X-Count"]
            };
        },
        validateFilterData(data) {
            let payload = { ...data };
            Object.keys(payload).forEach(key => {
                if (!payload[key]) {
                    delete payload[key];
                }
            });
            return payload;
        },
        deleteAllFilter() {
            this.filterList = {};
            this.emitFilterQuery();
        },
        deleteIndvFIlter(payload) {
            let { category, value } = payload;

            const asArray = Object.entries(this.filterList);

            const filtered = asArray.filter(([key, v]) => {
                return v != value.replace(key, "");
            });

            const newFIlterList = Object.fromEntries(filtered);
            this.filterList = newFIlterList;
            this.emitFilterQuery();
        },
        filterItemsData() {
            let payload = [];
            for (let key in this.filterList) {
                if (this.filterList[key] != null) {
                    payload.push({
                        category: key,
                        value: this.filterList[key]
                    });
                }
            }
            console.log(payload, "filter item list data");
            return payload;
        },
        openModalFilter(props) {
            console.log(props, "openModalFilter");
            let closeModalFunc;
            let payload = {
                initialData: this.filterList,
                action: data => {
                    const validated = this.validateFilterData(data);
                    this.filterList = validated;
                    this.emitFilterQuery();
                    closeModalFunc();
                }
            };
            let { close } = ModalService.open(TarifFilterModal, payload);
            closeModalFunc = close;
        },
        openAddTarifModal(props) {
            let closeModalFunc;
            let payload = {
                modalTitle: "Tambah Tarif",
                permissionObject: this.permissionObject,
                action: async data => {
                    let unblock;
                    try {
                        const payload = DataClass.postDataTarif(data);
                        if (payload) {
                            console.log(payload, "payload");
                            const confirmation = await this.addTarifConfirmation(
                                payload.nama,
                                payload.jumlah
                            );
                            console.log(payload);
                            if (confirmation.value) {
                                unblock = this.blockUi.blockModal();
                                const resp = await TarifRepo.postTarif(payload);
                                unblock();
                                if (!resp.error) {
                                    this.emitFilterQuery();
                                    closeModalFunc();
                                    this.showPopUp(
                                        "Data tarif berhasil di tambah",
                                        "success",
                                        "successImg"
                                    );
                                } else {
                                    throw resp.error;
                                }
                            }
                        }
                    } catch (error) {
                        if (typeof unblock == "function") {
                            unblock();
                        }
                        console.log(error, "error");
                        this.showErrorPopUp(error);
                    }
                }
            };
            let { close } = ModalService.open(AddTarifModal, payload);
            closeModalFunc = close;
        },
        async openEditTarifModal(props, isViewOnly) {
            let unblock;
            try {
                let closeModalFunc;
                console.log(props, "props");
                unblock = this.blockUi.blockPage();
                const initialData = await TarifRepo.getOneTarif(props.id);
                unblock();
                let modalTitle = "Edit Tarif";
                if (isViewOnly) {
                    modalTitle = "Detail Tarif";
                }
                let payload = {
                    modalTitle,
                    initialData: initialData,
                    isViewOnly: isViewOnly,
                    permissionObject: this.permissionObject,
                    action: async data => {
                        let unblock;
                        try {
                            console.log(data, "data");

                            const payload = DataClass.putDataTarif(data);
                            if (payload) {
                                const confirmation = await this.addTarifConfirmation(
                                    payload.nama,
                                    payload.jumlah,
                                    true
                                );
                                if (confirmation.value) {
                                    unblock = this.blockUi.blockModal();
                                    const resp = await TarifRepo.putTarif(
                                        payload
                                    );
                                    console.log(resp, "response 171");

                                    // const payload = this.validateData(data);
                                    // console.log(payload);
                                    // if (payload) {
                                    // unblock = this.blockUi.blockModal();
                                    // const resp = await subSpesialisasiRepo.putSubpspesialisasi(
                                    //     payload
                                    // );
                                    unblock();
                                    if (!resp.error) {
                                        this.emitFilterQuery();
                                        closeModalFunc();
                                        this.showPopUp(
                                            "Data tarif berhasil di edit",
                                            "success",
                                            "successImg"
                                        );
                                    }
                                }
                            }
                            // }
                        } catch (error) {
                            if (typeof unblock == "function") {
                                unblock();
                            }
                            console.log(error, "error");
                        }
                    }
                };
                let { close } = ModalService.open(AddTarifModal, payload);
                closeModalFunc = close;
            } catch (error) {
                console.log(error, "subspesialisasiEditModal error");
            }
        },
        async delConfirmation(id) {
            try {
                // delTarifConfirmation;
                const resp = await TarifRepo.delTarifConfirmation(id);
                if (resp.error) {
                    throw resp.error;
                }
                return resp;
            } catch (error) {
                this.showErrorPopUp(error);
            }
        },
        async prevDelConfrima({ id, nama }) {
            // let unblock;
            try {
                let html = `<div class="sub-delete">`;
                html += `<div class="data-warning">
                            <p>${nama}</p>
                            <p> ID: ${id} </p>
                         </div>`;
                html += `</div>`;
                const result = await new SweetAlert().show(
                    {
                        title:
                            "Apakah Anda yakin ingin menghapus data tarif berikut?",
                        html: html,
                        // text: "Data tarif akan terhapus secara permanen",
                        allowOutsideClick: false,
                        reverseButtons: true,
                        showCancelButton: true,
                        textConfirm: "Ya, hapus data",
                        textCancel: "Tidak, batalkan"
                    },
                    "dangerImg"
                );
                return result;
            } catch (error) {
                console.log(error);
                this.showErrorPopUp(error);
            }
        },
        async addTarifConfirmation(nama, jumlah, isEdit) {
            try {
                let html = `<div class="sub-delete">`;
                html += `<div class="data-warning"  style="background: #F3F8FF;">
                        <p>${nama}</p>
                        <p> Jumlah: ${jumlah} </p>
                     </div>`;
                html += `<p class="sub-delete__text">Data Tarif akan ${
                    isEdit ? "diubah" : "ditambah"
                } </p>`;
                html += `</div>`;
                const result = await new SweetAlert().show(
                    {
                        title: `Apakah Anda yakin ingin ${
                            isEdit ? "mengubah" : "menambah"
                        }  Data Tarif berikut?`,
                        html: html,
                        // text: "Data tarif akan terhapus secara permanen",
                        allowOutsideClick: false,
                        reverseButtons: true,
                        showCancelButton: true,
                        textConfirm: isEdit ? "Ya, Ubah" : "Ya, Tambah",
                        textCancel: "Tidak, Batalkan"
                    },
                    "warningImg"
                );
                return result;
            } catch (error) {
                //    if (typeof unblock == "function") {
                //         unblock();
                //     }
                console.log(error);
                this.showErrorPopUp(error);
            }
        },
        async deleteConfirmationTarif({ id, nama }) {
            let unblock;
            try {
                const prevConfrim = await this.prevDelConfrima({ id, nama });
                if (prevConfrim.value) {
                    unblock = this.blockUi.blockPage();
                    const confimation = await this.delConfirmation(id);
                    console.log(confimation, "confimation 348");
                    unblock();
                    if (confimation) {
                        let namaDokters;
                        if (confimation) {
                            namaDokters = DataClass.confirmationMapper(
                                confimation.dokters
                            );
                        }

                        let html = `<div class="sub-delete">`;
                        html += `<div class="data-warning">
                                <p>${nama}</p>
                                <p> ID: ${id} </p>
                             </div>`;
                        if (namaDokters.length > 0) {
                            html += `<div class="swal-dokter-container"> <p>Dokter terkait:</p> <div> ${namaDokters
                                .map(el => {
                                    return `<p>${el}</p>`;
                                })
                                .join("")}
                                     </div> </div>`;
                        }
                        // html += `<p class="sub-delete__text">Data tarif akan terhapus secara permanen</p>`;
                        html += `</div>`;
                        const result = await new SweetAlert().show(
                            {
                                title:
                                    "Apakah Anda yakin ingin menghapus data tarif berikut?",
                                html: html,
                                // text: "Data tarif akan terhapus secara permanen",
                                allowOutsideClick: false,
                                reverseButtons: true,
                                showCancelButton: true,
                                textConfirm: "Ya, hapus data",
                                textCancel: "Tidak, batalkan"
                            },
                            "dangerImg"
                        );
                        if (result.value) {
                            this.deleteTarif(confimation);
                        }
                    } else {
                        this.showPopUp(
                            "Data tarif berhasil dihapus",
                            "success",
                            "successImg"
                        );
                        this.emitFilterQuery();
                    }
                }
            } catch (error) {
                //    if (typeof unblock == "function") {
                //         unblock();
                //     }
                console.log(error);
                this.showErrorPopUp(error);
            }
        },
        async deleteTarif(confimation) {
            let unblock;
            try {
                unblock = this.blockUi.blockPage();
                const resp = await TarifRepo.delTarif(confimation);
                unblock();
                if (resp.error) {
                    throw resp.error;
                }
                this.showPopUp(
                    "Data tarif berhasil dihapus",
                    "success",
                    "successImg"
                );
                this.emitFilterQuery();
            } catch (error) {
                if (typeof unblock == "function") {
                    unblock();
                }
                this.showErrorPopUp(error);
            }
        },
        showPopUp(message, title, icon) {
            let messageStr = message ? message : "";
            let titleStr = title ? title : "";
            let iconStr = icon ? icon : "";
            new SweetAlert().show(
                {
                    title: titleStr,
                    // html: html,
                    text: messageStr,
                    allowOutsideClick: true,
                    // reverseButtons: true,
                    // showCancelButton: true,
                    textConfirm: "Ok",
                    timer: 3000
                },
                iconStr
            );
        },
        routeQueryToFilterList() {
            let filterQueryNames = ["id", "nama", "jumlah"];
            filterQueryNames.forEach(name => {
                if (this.$route.query[name]) {
                    this.filterList[name] = this.$route.query[name];
                }
            });
        }
    },
    created() {
        this.initPermissionCheck();
        this.routeQueryToFilterList();
    }
};
</script>

<style></style>
