<template>
    <div class="kt-content kt-grid__item kt-grid__item--fluid" id="kt_content">
        <div class="kt-portlet kt-portlet--mobile head-container-kt">
            <div
                style="border-bottom: unset;padding: 20px 20px 0 20px;"
                class="kt-portlet__head kt-portlet__head--lg header-container"
            >
                <base-title
                    style="width: 100%;"
                    class="head-label"
                    :head-title="headTitle"
                ></base-title>
                <div
                    class="kt-portlet__head-toolbar"
                    style="width: 100%;justify-content: flex-end;"
                >
                    <button
                        @click.prevent="getData"
                        type="button"
                        class="refresh-button"
                    >
                        <div>
                            <RefreshIcon style="width:14px;"></RefreshIcon>
                        </div>
                    </button>
                    <button
                        v-if="permissionObject.isCanCreateTarif"
                        @click.prevent="openAddTarifModal"
                        type="button"
                        class="btn btn-primary custom-modal-button"
                    >
                        <p>Tambah Tarif</p>
                        <i class="flaticon2-add-1"></i>
                    </button>
                </div>
            </div>
            <div class="kt-portlet__body">
                <div
                    class="row"
                    style="margin-bottom: 2em;flex-direction: row-reverse;"
                >
                    <!-- <div class="col-md-5 offset-md-3">
                        <SearchInput style="width:unset;display:none;" />
                    </div> -->
                    <div>
                        <FilterButton
                            :activeFilter="activeFilter()"
                            :clickedMethod="openModalFilter"
                        >
                        </FilterButton>
                    </div>
                </div>
                <div
                    v-if="activeFilter()"
                    style="padding: 0 20px 0 20px;"
                    class="selected-filter"
                >
                    <FilterItems
                        :clickedMethod="deleteIndvFilter"
                        :filterList="filterItemData()"
                    ></FilterItems>

                    <a @click.prevent="deleteAllFilter" style="cursor:pointer;">
                        Hapus Semua Filter</a
                    >
                </div>
                <div
                    class="record-limit"
                    style="display: flex;flex-direction: row-reverse;align-items: center;margin-bottom: 15px;"
                >
                    <select
                        @change="$refs.table.setLimit($event.target.value)"
                        name="limit"
                    >
                        <option
                            v-for="(value, index) in recordValues"
                            :key="index"
                            :value="value"
                            >{{ value }}</option
                        >
                    </select>
                    <label>Show Records :</label>
                </div>
                <div class="table-container">
                    <h3>Daftar Tarif</h3>
                    <v-server-table
                        id="holiday-table"
                        :columns="columns"
                        :options="options"
                        name="tarif_table"
                        ref="table"
                    >
                        <div slot="action" slot-scope="{ row }">
                            <BtnAction
                                :permissionObject="permissionObject"
                                :rowData="row"
                                :buttonMethod="buttonMethod"
                            ></BtnAction>
                        </div>
                    </v-server-table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BaseTitle from "../../_base/BaseTitle.vue";

import BtnAction from "../component/BtnAction.vue";

import FilterButton from "../../_base/FilterButton.vue";
import FilterItems from "../../_base/FilterItems.vue";
import SearchInput from "../../_base/SearchInput.vue";

import PublishChips from "../../_base/PublishChips.vue";
// import BtnAction from "../component/BtnAction.vue";
import RefreshIcon from "../../_icons/RefreshIcon.vue";

// import OptionDelete from "../component/OptionDeleteButton.vue";
// import OptionEdit from "../component/OptionEditButton.vue";

export default {
    name: "MasterTarifScreen",
    components: {
        BaseTitle,
        FilterButton,
        FilterItems,
        SearchInput,
        PublishChips,
        BtnAction,
        RefreshIcon
        // OptionDelete,
        // OptionEdit
    },
    props: {
        methodObject: { type: Object },
        buttonMethod: { type: Object },
        filterList: { type: Object },
        permissionObject: { type: Object }
    },
    methods: {
        filterItemData() {
            if (this.methodObject) {
                if (
                    typeof this.methodObject.filterItemsDataMethod == "function"
                ) {
                    return this.methodObject.filterItemsDataMethod();
                }
            }
        },
        deleteIndvFilter(payload) {
            if (this.methodObject) {
                if (
                    typeof this.methodObject.deleteIndvFIlterMethod ==
                    "function"
                ) {
                    this.methodObject.deleteIndvFIlterMethod(payload);
                }
            }
        },
        activeFilter() {
            let activeNumber = 0;
            if (this.filterList) {
                Object.keys(this.filterList).forEach(key => {
                    activeNumber++;
                });
                return activeNumber;
            }
        },
        serverTableInit() {
            if (this.methodObject) {
                if (this.methodObject.controllerReqFunctionMethod) {
                    this.options.requestFunction = this.methodObject.controllerReqFunctionMethod;
                }
                if (this.methodObject.controllerRespFunctionMethod) {
                    this.options.responseAdapter = this.methodObject.controllerRespFunctionMethod;
                }
            }
        },
        openModalFilter() {
            if (this.methodObject) {
                if (
                    typeof this.methodObject.openModalFilterMethod == "function"
                ) {
                    this.methodObject.openModalFilterMethod();
                }
            }
        },
        openAddTarifModal() {
            if (this.methodObject) {
                if (
                    typeof this.methodObject.openAddTarifModalMethod ==
                    "function"
                ) {
                    this.methodObject.openAddTarifModalMethod();
                }
            }
        },
        deleteAllFilter() {
            if (this.methodObject) {
                if (
                    typeof this.methodObject.deleteAllFilterMethod == "function"
                ) {
                    this.methodObject.deleteAllFilterMethod();
                }
            }
        },
        getData() {
            if (this.methodObject) {
                if (typeof this.methodObject.getDataTable == "function") {
                    this.methodObject.getDataTable();
                }
            }
        }
    },
    data() {
        return {
            headTitle: "Master Tarif",
            recordValues: [10, 25, 50, 100],
            columns: ["id", "nama", "jumlah", "deskripsi", "action"],
            options: {
                texts: {
                    filterBy: "Cari",
                    loadingError: "testing"
                },
                filterable: false,
                headings: {
                    id: "ID",
                    nama: "Tarif",
                    deskripsi: "Deskripsi",
                    jumlah: "Jumlah",
                    action: "Actions"
                },
                sortIcon: {
                    base: "fas",
                    is: "fa-sort",
                    up: "fa-sort-up",
                    down: "fa-sort-down"
                },
                sortable: ["jumlah"],
                perPageValues: [],
                toMomentFormat: "YYYY-MM-DD",
                filterByColumn: false,
                requestFunction: null,
                responseAdapter: null,
                customFilters: ["filterQuery"],
                templates: {
                    jumlah(h, { jumlah }) {
                        // return jumlah with indonesian currency format
                        return `Rp. ${jumlah.toLocaleString("id-ID")}`;
                    },
                    deskripsi(h, row) {
                        let deskripsi = " - ";
                        if (row.deskripsi) {
                            deskripsi = row.deskripsi;
                        }
                        return deskripsi;
                    }
                }
            }
        };
    },
    created() {
        this.serverTableInit();
    }
};
</script>

<style></style>
