<template>
    <Modal class="custom-modal doctor-filter">
        <ModalHeader class="custom-header"
            ><h3>{{ modalTitle }}</h3>
            <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                @click.prevent="dismiss(idComponent)"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </ModalHeader>
        <ModalBody>
            <form class="form-modal">
                <div v-if="tarifData.id" class="form-group">
                    <label class=" col-form-label form-label">ID</label>
                    <div>
                        <span style="margin-left: 12px;">{{
                            tarifData.id | strip
                        }}</span>
                    </div>
                </div>
                <div class="form-group">
                    <label class=" col-form-label form-label"
                        >Tarif<span style="color:red;">*</span></label
                    >
                    <div v-if="!isViewOnly">
                        <!-- <VueInputText
                            :placeholder="'Tulis Nama Tarif'"
                            :validation="'required'"
                            :validationName="'Nama Tarif'"
                            :scope="'nama-tarif'"
                            v-model="tarifData.nama"
                        >
                        </VueInputText> -->
                        <input
                            type="text"
                            class="form-control"
                            v-model="tarifData.nama"
                            placeholder="Tulis Nama Tarif"
                            v-validate="'required'"
                            data-vv-as="Nama Tarif"
                            name="Tarif"
                            :class="{ 'is-invalid': errors.has('Tarif') }"
                        />
                        <div
                            v-show="errors.first('Tarif')"
                            class="invalid-feedback"
                        >
                            {{ errors.first("Tarif") }}
                        </div>
                    </div>
                    <div v-else>
                        <span style="margin-left: 12px;">{{
                            tarifData.nama | strip
                        }}</span>
                    </div>
                </div>
                <div class="form-group">
                    <label class="col-form-label form-label"
                        >Jumlah<span style="color:red;">*</span></label
                    >
                    <div class="d-flex" style="align-items: center;flex-wrap: wrap;">
                        <span>Rp.</span>
                        <input
                            style="width: 50%;"
                            v-if="!isViewOnly"
                            type="number"
                            class="form-control"
                            v-model="tarifData.jumlah"
                            placeholder="Tulis jumlah"
                            v-validate="'required'"
                            data-vv-as="jumlah"
                            name="jumlah"
                            :class="{ 'is-invalid': errors.has('jumlah') }"
                        />
                        <div
                            v-if="!isViewOnly"
                            v-show="errors.first('jumlah')"
                            class="invalid-feedback"
                        >
                            {{ errors.first("jumlah") }}
                        </div>
                        <span v-if="isViewOnly" style="margin-left: 12px;">{{
                            tarifData.jumlah | strip
                        }}</span>
                    </div>
                </div>
                <div class="form-group">
                    <label class=" col-form-label form-label">Deskripsi</label>
                    <div v-if="!isViewOnly">
                        <VueInputText
                            :placeholder="'Tulis Tarif Deskripsi'"
                            :validationName="'Tarif Deskripsi'"
                            v-model="tarifData.deskripsi"
                        >
                        </VueInputText>
                    </div>
                    <div v-else>
                        <span style="margin-left: 12px;">{{
                            tarifData.deskripsi | strip
                        }}</span>
                    </div>
                </div>
            </form>
        </ModalBody>

        <ModalFooter class="custom-footer">
            <div v-if="!isViewOnly" class="d-flex">
                <a
                    class="link-batal"
                    style="margin-right: 40px;line-height: 40px;"
                    @click.prevent="dismiss(idComponent)"
                    >Batal</a
                >
                <button
                    v-if="permissionCheck('isCanCreateTarif') || permissionCheck('isCanEditTarif')"
                    :disabled="isEmpty()"
                    @click.prevent="validateData"
                    class="btn btn-primary custom-modal-button"
                >
                    Simpan
                </button>
            </div>
            <div v-else>
                <button
                    v-if="permissionCheck('isCanEditTarif')"
                    @click.prevent="editModal"
                    class="btn btn-primary custom-modal-button"
                >
                    Edit
                </button>
            </div>
        </ModalFooter>
    </Modal>
</template>

<script>
// import PlaceHolderImage from "../../assets/placeholder.png";

import Modal from "../../../_modal/Modal.vue";
import ModalHeader from "../../../_modal/ModalHeader.vue";
import ModalBody from "../../../_modal/ModalBody.vue";
import ModalFooter from "../../../_modal/ModalFooter.vue";
import ModalMixin from "../../../../mixins/ModalMixins";

import VueInputText from "../../../_general/VueInputText.vue";

// import EyeIconSlashed from "../../../_icons/EyeIconSlashed.vue";
// import EyeFillIcon from "../../../_icons/EyeFillIcon.vue";

// import FileInput from "../../../_base/FileInputCustom.vue";
// import Multiselect from "vue-multiselect";

import VeeValidate from "vee-validate";
VeeValidate.setMode("passive");

export default {
    name: "KlinikAddDataModal",
    components: {
        Modal,
        ModalHeader,
        ModalBody,
        ModalFooter,

        // Multiselect,
        // FileInput,
        // EyeIconSlashed,
        // EyeFillIcon,
        VueInputText
    },
    mixins: [ModalMixin],
    provide() {
        return {
            $validator: this.$validator
        };
    },
    data() {
        return {
            tarifData: {
                id: null,
                nama: null,
                deskripsi: null,
                jumlah: null
            },
            isViewOnly: false,
            modalTitle: "Tambah Tarif"
        };
    },
    // props: {
    //     modalTitle: {
    //         type: String,
    //         default: "Tambah Klinik"
    //     }
    // },
    filters: {
        strip(text) {
            return text ? text : " - ";
        }
    },
    methods: {
        isEmpty() {
            const isEmpty = _.values(this.tarifData).every(_.isEmpty);
            return isEmpty;
        },
        validateData() {
            this.$validator.validateAll().then(result => {
                console.log(result);
                if (result) {
                    this.sendData(this.tarifData);
                }
            });
        },
        sendData(data) {
            if (this.componentData) {
                if (typeof this.componentData.action == "function") {
                    this.componentData.action(data);
                }
            }
        },
        editModal() {
            this.isViewOnly = false;
            this.modalTitle = "Edit Tarif";
        },
        permissionCheck(permission){
            let permissionBool = true
            if (this.componentData) {
                if(this.componentData.permissionObject){
                    if (!this.componentData.permissionObject[permission]) {
                        permissionBool = this.componentData.permissionObject[permission]
                    }
                }
            }
            return permissionBool
        }
    },
    created() {
        if (this.componentData) {
            if (this.componentData.initialData) {
                this.tarifData.id = this.componentData.initialData.ID;
                this.tarifData.nama = this.componentData.initialData.nama;
                this.tarifData.deskripsi = this.componentData.initialData.description;
                this.tarifData.jumlah = this.componentData.initialData.jumlah;
            }
            if (this.componentData.isViewOnly) {
                this.isViewOnly = this.componentData.isViewOnly;
            }
            if (this.componentData.modalTitle) {
                this.modalTitle = this.componentData.modalTitle;
            }
        }
    }
};
</script>

<style></style>
