export default class PermissionClass {
    static isRenderByPermission(arrayPermission) {
        let permissionObject = {
            isCanCreateTarif: false,
            isCanEditTarif: false,
            isCanDeleteTarif: false,
            isCanViewDetailTarif: false
        };
        let permissionName = {
            isCanCreateTarif: "DoctorResource.POST.CreateTarif",
            isCanEditTarif: "DoctorResource.PUT.UpdateTarif",
            isCanDeleteTarif: "DoctorResource.DEL.DeleteTarif",
            isCanViewDetailTarif: "DoctorResource.View.TarifDetail"
        };

        if (arrayPermission) {
            if (Array.isArray(arrayPermission)) {
                Object.keys(permissionName).forEach(key => {
                    if (arrayPermission.includes(permissionName[key])) {
                        permissionObject[key] = true;
                    }
                });
            }
        }

        return permissionObject;
    }
}
